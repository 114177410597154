import React, { useEffect } from "react";
import "./Footer2.scss";
import facebookIcon from "../../Assets/icons/Facebook_white.png";
import linkedINIcon from "../../Assets/icons/LinkedIN_white.png";
import twitterIcon from "../../Assets/icons/Twitter_white.png";
import treehouseIcon from "../../Assets/icons/Treehouse_white.png";
import githubIcon from "../../Assets/icons/Github_white.png";
import footerLogo from "../../Assets/icons/footer-logo.svg";
import backToTop from "../../Assets/icons/back-to-top-btn.png";
import instagram from "../../Assets/icons/instagram.png";
function Footer2() {
  const [cookieAvailable, setCookieAvailable] = React.useState(false);
  const getLocalstorage = () => {
    const localStorage = window.localStorage.getItem("cookies");
    setCookieAvailable(localStorage);
  };
  useEffect(() => {
    getLocalstorage();
  }, []);
  const backtoTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer>
        <div className="MainDiv1">
          <div className="MainInnerDiv1">
            <img src={footerLogo} style={{height:"75px"}}/>
            <p>
              TROR is an artificial intelligence consultancy specialising in
              developing powerful and customized AI solutions for business. With
              top AI Experts we take pride in providing the best cutting-edge AI
              consultancy. Our years of experience in various industries helps
              us to develop and implement bespoke AI solutions for businesses.
              Our on demand AI products have helped over 100 companies drive
              transformational results.
            </p>
          </div>
          <div className="MainInnerDiv2">
            <span className="fontBold">Quick Links</span>
            <ul className="footer-cat-links fontRegular">
              <li>
                <a onClick={backtoTop} href="/">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="/WhoWeAre">
                  <span>Who We Are ?</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/services">
                  <span>Services</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/products">
                  <span>Products</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/industry">
                  <span>Industry</span>
                </a>
              </li>
              <li>
                <a href="/articles">
                  <span>Articles</span>
                </a>
              </li>
              <li>
                <a href="/career">
                  <span>Career</span>
                </a>
              </li>
              <li>
                <a href="/h1b">
                  <span>H1B Application</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/contactus">
                  <span>Contact Us</span>
                </a>
              </li>
              {/* <li><a href=""><span>About Us</span></a></li> */}
            </ul>
          </div>
          <div className="MainInnerDiv3">
            <span className="footer-cat fontBold">Our Services</span>
            <ul className="footer-cat-links fontRegular">
              <li>
                <a onClick={backtoTop} href="/services/Machine+Learning">
                  <span>Machine Learning</span>
                </a>
              </li>
              <li>
                <a
                  onClick={backtoTop}
                  href="/services/Natural+language+processing"
                >
                  <span>Natural Language Processing</span>
                </a>
              </li>
              <li>
                <a
                  onClick={backtoTop}
                  href="/services/Computer+Vision+Services"
                >
                  <span>Computer Vision Services</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/services/Predictive+analytics">
                  <span>Predictive Analytics</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/services/Data+Labelling+services">
                  <span>Data Labelling Services</span>
                </a>
              </li>
              <li>
                <a
                  onClick={backtoTop}
                  href="/services/Web+development"
                >
                  <span>Web Development</span>
                </a>
              </li>
              <li>
                <a
                  onClick={backtoTop}
                  href="/services/App+development"
                >
                  <span>App Development</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/services/DevOps">
                  <span>DevOps</span>
                </a>
              </li>
              <li>
                <a onClick={backtoTop} href="/services/Big-Data+Services">
                  <span>Big-Data Services</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="MainInnerDiv4">
            <div className="MainInnerDiv41">
              <span className="fontBold">Certificates</span>
              <div>
                <img src="/images/cert1.svg"  />
                <img src="/images/cert2.png" width={"75px"}/>
              </div>
            </div>
            <div className="MainInnerDiv42">
              <span className="fontBold">Follow Us</span>
              <div>
                <a
                  href="https://www.linkedin.com/company/tror-ai"
                  target="_blank"
                >
                  <img src={linkedINIcon} />
                </a>
                <a
                  href="https://www.facebook.com/tror.ai.consultancy"
                  target="_blank"
                >
                  <img src={facebookIcon} />
                </a>
                <a target="_blank" href="https://twitter.com/ai_tror">
                  <img src={twitterIcon} />
                </a>
                <a target="_blank" href="https://www.instagram.com/tror.ai_/">
                  <img src={instagram} />
                </a>
                {/* <a href="">
										<img src={treehouseIcon} />
									</a> */}
                <a href="https://github.com/trorai/">
                  <img src={githubIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Border100"></div>
        <div className="MainDiv2">
          <div className="MainDiv21">@{new Date().getFullYear()}{" "} Tror LLC. All Rights Reserved.</div>
          {/* <div className="developBy">Developed with 💙 by <a href="https://www.sofodel.com" target="_blank" style={{color:"white"}}><b>Sofodel</b></a></div> */}
          <div className="MainDiv22 fontRegular">
            <span>
              <a href="/TermsAndConditions">Terms & Condition</a>
            </span>
            <span>|</span>
            <span>
              <a href="/PrivacyPolicy">Privacy Policy</a>
            </span>
            <span>|</span>
            <span>
              <a href="/sitemap.xml">Sitemap</a>
            </span>
          </div>
          {/* <div className="developByMobile">Developed with 💙 by <a href="https://www.sofodel.com" target="_blank" style={{color:"white"}}><b>Sofodel</b></a></div> */}
        </div>
      </footer>
      {cookieAvailable ? (
        ""
      ) : (
        <div className="CookiesBox">
          <p>
            This website collects cookies to deliver better user experience . we
            never collect any personal data .{" "}
            <a href="/PrivacyPolicy">Read More</a>
          </p>
          <button
            onClick={() => {
              window.localStorage.setItem("cookies", true);
              setCookieAvailable(true);
            }}
          >
            Accept cookies
          </button>
        </div>
      )}
    </>
  );
}

export default Footer2;
