/* eslint-disable no-useless-constructor */
import React, { Component, Fragment, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer2 from "../Components/Footer/Footer2";
import Loading from "../Components/Loader/Loader";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";
import ScrollToTopIcon from "../Components/ScrollToTop/ScrollToTopIcon";
// import Industry from './Industry/Industry.jsx';
// import Products from './Products/Products.jsx';
// import Services from './Services/Services.jsx';
const HomePage = React.lazy(() => import("./HomePage/HomePage"));
const LandingPagePrimary = React.lazy(() => import("./landing-vert/LandingPage.jsx"));
const LandingPage = React.lazy(() => import("./landing/LandingPage.jsx"));
const Header = React.lazy(() => import("../Components/Header/Header"));
const Footer = React.lazy(() => import("../Components/Footer/Footer"));
const Products = React.lazy(() => import("./Products/Products"));
const Services = React.lazy(() => import("./Services/Services"));
const Industry = React.lazy(() => import("./Industry/Industry"));
const Articles = React.lazy(() => import("./Articles/Articles"));
const ContactUs = React.lazy(() => import("./ContactUs/ContactUs"));
const H1B = React.lazy(() => import("./H1B/h1b"));

const Career = React.lazy(() => import("./career/Career"));
const whoWeAre = React.lazy(() => import("../Components/WhoWeAre/WhoWeAre.js"));
const Category = React.lazy(() => import("./Category/Category"));
const ServicesPage = React.lazy(() =>
	import("../Components/services page/ServicesPage")
);
const ProductPage = React.lazy(() =>
	import("../Components/Product page/ProductPage")
);
const ArticlePage = React.lazy(() =>
	import("../Components/Articles Page/ArticlePage")
);
const TermPrivacy = React.lazy(() =>
	import("../Components/Term&Privacy/TermPrivacy")
);
const TermAndCondition = React.lazy(() =>
	import("../Components/Term&Privacy/TermsAndCondition.js")
);
export class Routesconfig extends Component {
	constructor(props) {
		super(props);
	}
	state = {
		loading: true,
	};
	componentDidMount() {
		this.isLoading = setTimeout(() => {
			this.setState({ loading: false });
		}, 2300);
	}
	componentWillUnmount() {
		clearTimeout(this.isLoading);
	}

	timer = () =>
		setTimeout(() => {
			this.setState({ loading: false });
		}, 2300);
	render() {
		const { loading } = this.state;
		return loading ? (
			<Loading />
		) : (
			<Router>
				<ScrollToTop/>
				<Suspense fallback={<Loading />}>
					<Switch>
						{/* <div className="route-container"> */}
						<Fragment>
							<Header />
							<Route path="/" component={HomePage} exact></Route>
							<Route path="/primary" component={LandingPagePrimary} exact></Route>
							<Route path="/secondary" component={LandingPage} exact></Route>
							<Route path="/industry" component={Industry} exact></Route>
							<Route path="/products" component={Products} exact></Route>
							<Route path="/services" component={Services} exact></Route>
							<Route path="/articles" component={Articles} exact></Route>
							<Route path="/contactus" component={ContactUs} exact></Route>
							<Route path="/h1b" component={H1B} exact></Route>
							<Route path="/career" component={Career} exact></Route>
							<Route
								path="/services/:service"
								component={ServicesPage}
								exact
							></Route>
							<Route
								path="/products/:product"
								component={ProductPage}
								exact
							>
							</Route>
							<Switch>
							<Route path="/articles/category" component={Category} exact></Route>
							<Route
								path="/articles/:article"
								component={ArticlePage}
								exact
							></Route>
							</Switch>
							<Route path="/whoWeAre" component={whoWeAre} exact></Route>
							<Route exact path="/articles/category/blogs">
								<Articles category="Blogs" />
							</Route>
							<Route exact path="/articles/category/news">
								<Articles category="News & Updates" />
							</Route>
							<Route exact path="/articles/category/caseStudy">
								<Articles category="Case Studies" />
							</Route>
							<Route exact path="/TermsAndConditions">
								<TermAndCondition title="Terms And Conditions" />
							</Route>{" "}
							<Route exact path="/PrivacyPolicy">
								<TermPrivacy title="Privacy Policy" />
							</Route>
							<Footer2 />
							<ScrollToTopIcon/>
						</Fragment>
						{/* </div> */}
					</Switch>
				</Suspense>
			</Router>
		);
	}
}
export default Routesconfig;
