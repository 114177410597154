import React, { useEffect } from "react";

import "./App.scss";
import Routesconfig from "./Routes/Routesconfig";

function App() {
  useEffect(() => {
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
  });
  return <Routesconfig />;
}

export default App;

// src="https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert1.svg"
//  https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert2.svg
// https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/cert3.svg
